// fade in animation
.fade-in {
    animation: fadeIn ease .5s;
    -webkit-animation: fadeIn ease .5s;
    -moz-animation: fadeIn ease .5s;
    -o-animation: fadeIn ease .5s;
    -ms-animation: fadeIn ease .5s;
}

@keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}

@-moz-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}

@-o-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}

@-ms-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}
/* end fade in  */

// screen show animation
.screen-out {
    animation: resize 2s ease-out;
    -webkit-animation: resize 2s ease-out;
    -moz-animation: resize 2s ease-out;
    -o-animation: resize 2s ease-out;
    -ms-animation: resize 2s ease-out;
}
.fader{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
    background-color: #07080D !important;
}
/* Chrome, Safari, Opera */
@keyframes resize {
    0% {width: 0; height: 0;}
    100% {width: 100%;height: 100%;}
}

@-moz-keyframes resize {
    0% {opacity:0;width: 0px;height: 0px}
    50% {opacity:0.5;width:  100vw;height:  100vh}
    100% {opacity:1;width: 100vw; height: 100vh;}
}

@-webkit-keyframes resize {
    0% {opacity:0;width: 0px;height: 0px}
    50% {opacity:0.5;width:  100vw;height:  100vh}
    100% {opacity:1;width: 100vw; height: 100vh;}
}

@-o-keyframes resize {
    0% {opacity:0;width: 0px;height: 0px}
    50% {opacity:0.5;width:  100vw;height:  100vh}
    100% {opacity:1;width: 100vw; height: 100vh;}
}

@-ms-keyframes resize {
    0% {opacity:0;width: 0px;height: 0px}
    50% {opacity:0.5;width:  100vw;height:  100vh}
    100% {opacity:1;width: 100vw; height: 100vh;}
}


.slide-fade-right-enter {
    opacity: 0;
    transform: translateX(10px) rotate(45deg);
    pointer-events: nne;
}
.slide-fade-right-enter-active {
    transition: all 0.25s ease-in-out;
    transition-delay: 0.1s;
    transform: translateX(0px) rotate(0deg);
    position: relative;
    opacity: 100;
}

.slide-fade-right-exit {
    transform: translateX(0px) rotate(0deg);
    pointer-events: none;
}

.slide-fade-right-exit-active {
    opacity: 0;
    transition: all 0.25s ease-in-out;
    transform: translateX(30px) rotate(-80deg);
    position: relative;
}

.slide-fade-up-enter {
    transform: translateY(15px);
    pointer-events: none;
    opacity: 0;
}

.slide-fade-up-enter-active {
    transition: all 0.25s ease-in-out;
    transition-delay: 0.1s;
    transform: translateY(0px);
    position: relative;
    opacity: 1;
}

.slide-fade-up-enter-done {
    transform: translateY(0px);
    opacity: 1;
}

.slide-fade-up-exit {
    transform: translateY(0px);
    pointer-events: none;
    opacity: 1;
}

.slide-fade-up-exit-active {
    transition: all 250ms ease-in-out;
    transform: translateY(-15px);
    position: relative;
    opacity: 0;
}

.slide-fade-up-exit-done {
    opacity: 0;
}

.zoom-in-out-enter {
    transform: translateY(6px) scale(3.5);
    transition-delay: 0.1s;
    filter: blur(0.4px);
    opacity: 0;
}

.zoom-in-out-enter-active {
    transition: all 250ms ease-in-out;
    transform: translateY(0px) scale(1);
    filter: blur(0px);
    opacity: 1;
}

.zoom-in-out-enter-done {
    opacity: 1;
}

.zoom-in-out-exit {
    opacity: 1;
}

.zoom-in-out-exit-active {
    transition: all 150ms ease-in-out;
    transition-delay: 0.1s;
    transform: translateY(4px) scale(2.3);
    filter: blur(0.4px);
    position: relative;
}