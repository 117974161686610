@import "../../styles/variables";
@import "../../styles/mixins";

.icon-dashboard {
  background-image: url(../../assets/icons/donut_small.svg);
  background-repeat: no-repeat;
  display: block;
  width: 20px;
  height: 20px;
}

.active .icon-dashboard {
  background-image: url(../../assets/icons/donut_small_active.svg);
  background-repeat: no-repeat;
  display: block;
}
.icon-datacenter {
  background-image: url(../../assets/icons/network_check.svg);
  background-repeat: no-repeat;
  display: block;
  width: 20px;
  height: 20px;
}

.active .icon-datacenter {
  background-image: url(../../assets/icons/network_check_active.svg);
  background-repeat: no-repeat;
  display: block;
  width: 20px;
  height: 20px;
}
.icon-shop {
  background-image: url(../../assets/icons/shopping_cart.svg);
  background-repeat: no-repeat;
  display: block;
  width: 20px;
  height: 20px;
}

.active .icon-shop {
  background-image: url(../../assets/icons/shopping_cart_active.svg);
  background-repeat: no-repeat;
  display: block;
  width: 20px;
  height: 20px;
}

.icon-account {
  background-image: url(../../assets/icons/account_circle.svg);
  background-repeat: no-repeat;
  display: block;
  width: 20px;
  height: 20px;
}

.active .icon-account {
  background-image: url(../../assets/icons/account_circle_active.svg);
  background-repeat: no-repeat;
  display: block;
  width: 20px;
  height: 20px;
}

.icon-admin {
  background-image: url(../../assets/icons/admin_panel_settings.svg);
  background-repeat: no-repeat;
  display: block;
  width: 20px;
  height: 20px;
}

.active .icon-admin {
  background-image: url(../../assets/icons/admin_panel_settings_active.svg);
  background-repeat: no-repeat;
  display: block;
  width: 20px;
  height: 20px;
}
.icon-orders {
  background-image: url(../../assets/icons/network_check.svg);
  background-repeat: no-repeat;
  display: block;
  width: 20px;
  height: 20px;
}

.active .icon-orders {
  background-image: url(../../assets/icons/network_check_active.svg);
  background-repeat: no-repeat;
  display: block;
  width: 20px;
  height: 20px;
}
.icon-document {
  background-image: url(../../assets/icons/document.svg);
  background-repeat: no-repeat;
  display: block;
  width: 20px;
  height: 20px;
}

.active .icon-document {
  background-image: url(../../assets/icons/document_active.svg);
  background-repeat: no-repeat;
  display: block;
  width: 20px;
  height: 20px;
}
.icon-faq {
  background-image: url(../../assets/icons/network_check.svg);
  background-repeat: no-repeat;
  display: block;
  width: 20px;
  height: 20px;
}

.active .icon-faq {
  background-image: url(../../assets/icons/network_check_active.svg);
  background-repeat: no-repeat;
  display: block;
  width: 20px;
  height: 20px;
}
.sidebar {
  width: 280px;
  display: flex;
  flex-direction: column;
  background: #131419;
  .logo {
    width: 100%;
    display: flex;
    a {
      width: 80%;
      margin: auto;
      display: flex;
      text-decoration: none;
      img,
      h1 {
        margin: auto;
      }
      img {
        height: 30px;
        margin-right: 10px;
      }
      h1 {
        margin-left: 10px;
        color: #ffffff;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        text-decoration: none;
        letter-spacing: 0.32em;
      }
      small {
        margin-left: 10px;
        font-size: 10px;
        color: #a7a7a9;
        transform: translateY(0px);
        position: absolute;
        letter-spacing: 0.2em;
      }
    }
  }
  .divider {
    border: 1px solid #2d2e33;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
  }
  ul {
    list-style: none;
    transition: 0.1s ease-in-out;
    padding-left: 3rem !important;
    a {
      width: 100%;
      display: flex;
      flex-direction: row;
      text-decoration: none;
    }
    .icon {
      margin-right: 15px;
    }
    .r-border {
      margin-right: 0;
      margin-left: auto;
      width: 4px;
      background: $blue;
      display: none;
    }
    li {
      margin-right: 0px;
      a {
        height: 40px;
        display: flex;
        flex-direction: row;
        text-decoration: none;
        .icon,
        .title {
          margin-top: auto;
          margin-bottom: auto;
        }
      }
      a,
      .title {
        color: #5f5f5f;
        font-weight: 600;
        font-size: 14px;
      }
      &.active,
      &:hover {
        a,
        .title {
          color: white;
          font-weight: 600;
          font-size: 14px;
        }
      }
      &.active {
        .r-border {
          display: block;
        }
      }
    }
  }
  .logout-btn {
    width: 70%;
    background-color: #07080d;
    border-radius: 5px;
    display: flex;
    cursor: pointer;
    height: 50px;
    &:hover {
      span {
        color: white;
      }
    }
    img {
      margin: auto;
      margin-right: 13px;
      margin-left: 55px;
      width: 20px;
      height: 20px;
    }
    span {
      margin: auto;
      margin-left: 13px;
      color: #afafaf;
      display: block;
      font-weight: 600;
      font-size: 14px;
    }
  }
}

@media (max-width: 768px) {
  .main-content {
    width: calc(100% - 55px);
    //padding: 20px 120px;
  }
  .sidebar {
    width: 55px;
    .logo {
      img {
        width: 80%;
        margin: auto !important;
      }
      h1,
      small {
        display: none;
      }
    }
    .divider {
      margin-bottom: 0 !important;
    }
    ul {
      padding: 0 !important;
      li {
        margin-right: 0 !important;
      }
      .icon {
        margin-right: auto !important;
        margin-left: auto !important;
        margin-bottom: 5px;
        //.icon-dashboard{
        //    width: 28px;
        //}
      }
    }
    .title,
    .r-border {
      display: none !important;
    }
    .logout-btn {
      span {
        display: none;
      }
      img {
        margin: auto;
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .main-content {
    width: calc(100% - 55px);
    //padding: 20px 120px;
  }
  .sidebar {
    width: 55px;
    .logo {
      img {
        width: 80%;
        margin: auto !important;
      }
      h1,
      small {
        display: none;
      }
    }
    .divider {
      margin-bottom: 0 !important;
    }
    ul {
      padding: 0 !important;
      li {
        margin-right: 0 !important;
      }
      .icon {
        margin-right: auto !important;
        margin-left: auto !important;
        margin-bottom: 5px;
        //.icon-dashboard{
        //    width: 28px;
        //}
      }
    }
    .title,
    .r-border {
      display: none !important;
    }
    .logout-btn {
      span {
        display: none;
      }
      img {
        margin: auto;
      }
    }
  }
}

@media (min-width: 992px) and (max-width: 1750px) {
  .main-content {
    width: calc(100% - 55px);
    //padding: 20px 120px;
  }
  .sidebar {
    width: 55px;
    .logo {
      img {
        width: 80%;
        margin: auto !important;
      }
      h1,
      small {
        display: none;
      }
    }
    .divider {
      margin-bottom: 0 !important;
    }
    ul {
      padding: 0 !important;
      li {
        margin-right: 0 !important;
      }
      .icon {
        margin-right: auto !important;
        margin-left: auto !important;
        margin-bottom: 5px;
        //.icon-dashboard{
        //    width: 28px;
        //    height: 28px;
        //}
      }
    }
    .title,
    .r-border {
      display: none !important;
    }
    .logout-btn {
      span {
        display: none;
      }
      img {
        margin: auto;
      }
    }
  }
}

@media (min-width: 1750px) {
  .sidebar {
    .logout-btn {
      img {
        display: none;
      }
      span {
        margin: auto;
      }
    }
  }
}
