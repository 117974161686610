.shop-content {
  .content {
    .plan-option {
      .title {
        color: #92f8ff;
        font-weight: bold;
        font-size: 18px;
        padding-left: 0 !important;
        margin-left: 0 !important;
      }
      .shop-cards {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 10px;
        margin-left: -12px;
        margin-right: -12px;
        .shop-card {
          flex: 1;
          min-width: 280px;
          background-color: #131419;
          height: 225px;
          border-radius: 5px;
          margin: 0 !important;
          padding: 26px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .card-title {
            font-size: 15px;
            font-weight: bold;
          }
          .card-detail {
            font-size: 12px;
            font-weight: 600;
            color: #5f5f5f;
          }
          .card-footer {
            display: flex;
            justify-content: space-between;
            gap: 10px;
            //flex-wrap: wrap;
            border: none;
            background-color: transparent;
            padding: 0;
            .group-input {
              font-family: Montserrat;
              width: 100%;
              padding-left: 0 !important;
              margin-left: 0 !important;
              max-width: 298px;
              .custom-select {
                background: #131419 url("../../assets/icons/arrow-down.svg");
                background-position: 95% center !important;
                background-repeat: no-repeat !important;
                background-color: #07080d;
                &.open {
                  background: #26272f url("../../assets/icons/arrow-up.svg") !important;
                  background-position: 98% center !important;
                  background-repeat: no-repeat !important;
                }
                .select-selected {
                  padding: 0 22px;
                  display: flex;
                  align-items: center;
                }
              }
            }
            .price {
              font-weight: 700;
              font-size: 19px;
              line-height: 23px;
              color: white;
              margin-top: 21px;
            }
            .price2 {
              font-weight: 600;
              font-size: 10px;
              line-height: 12px;
              color: #5f5f5f;
            }
            .purchase {
              width: 119px;
              background-color: #07080d;
              border-radius: 5px;
              color: #afafaf;
              font-size: 12px;
              font-weight: bold;
              border: none;
              outline: none;
              height: 44px;
              padding: 12px 20px;
              &:hover {
                color: #92f8ff;
              }

              &:disabled {
                cursor: not-allowed;
              }
            }

            .residential-slider-wrap {
              position: relative;

              .current-value {
                position: absolute;
                font-size: 13px;
                font-weight: 700;
                top: 50px;
                width: 50px;
              }
            }

          }
        }
      }

      .membership {
        width: 33.33%;

        .membership-price {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          font-size: 12px;
          font-weight: 600;
          color: #5f5f5f;
          padding: 12px 22px;
          background-color: #07080d;
          border-radius: 5px;
          height: 44px;
          width: 100%;
        }
      }
    }
  }
  .modal {
    position: fixed;
    background: rgba($color: transparent, $alpha: 0.5);
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 1050;

    &.active {
      display: flex;
      justify-content: center;
      align-items: center;
      animation: fadeIn 0.3s;
      background: rgba(0, 0, 0, 0.9);
    }

    &.inactive {
      display: none;
    }

    .modal-inside-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100vh;
      width: calc(100% - 330px);
    }

    .modal-inside {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 1300px;
      height: 80%;
      padding: 0 50px;
      border-radius: 10px;
      background-color: #131419;

      #close-button {
        position: absolute;
        cursor: pointer;
        font-size: 2em;
        top: 15px;
        right: 15px;
      }

      .order-details {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        height: 500px;
        width: 40%;

        font-size: 18px;
        font-weight: bold;

        .cart-details {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 125px;
          margin: 50px 0;
          .cart-item {
            display: flex;
            padding: 0 40px;
            justify-content: space-between;
            align-items: center;
            border-radius: 10px;
            background-color: #07080d;
            height: 70%;

            img {
              width: 25px;
              height: 25px;
            }

            .cart-name {
              margin: 10px;
              color: #92f8ff;
              font-size: 18px;
              font-weight: 600;
              // white-space: nowrap;
            }

            .cart-price {
              font-weight: 600;
              font-size: 18px;
              color: #9bffa5;
              white-space: nowrap;
            }
          }
        }

        .bill {
          display: flex;
          flex-direction: column;
          height: 200px;

          .divider {
            height: 1px;
            background: #2f2f2f;
            margin-top: 18px;
            margin-bottom: 18px;
          }

          .list-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-weight: 600;
            font-size: 16px;
            padding: 0 20px;
            height: 20%;
            width: 100%;

            .list-item-title {
              color: #6e6e6e;
            }

            .discount-price {
              color: #92f8ff;
            }
          }

          .total-price {
            display: flex;
            padding: 0 23px;
            align-items: center;
            justify-content: space-between;
            .total-title {
              font-size: 14px;
              color: #ffffff;
              font-weight: bold;
            }
            .total-price-value {
              font-weight: 600;
              font-size: 18px;
              color: #9bffa5;
            }
          }
        }
      }

      .discount {
        display: flex;
        height: 17.5%;
        flex-direction: column;
        justify-content: space-between;
      }

      .discount-input-wrapper {
        display: flex;
        height: 50%;
        width: 100%;

        input {
          padding: 0 20px;
          width: 100%;
          border-radius: 10px;
          color: #92f8ff;
          background-color: #07080d;
          border: none;
          outline: none;
        }
      }

      .checkout-window {
        height: 80%;
        width: 50%;
      }
    }
  }
}
@media (max-width: 1200px) {
  .modal-inside,
  .modal-inside-wrapper {
    width: 100% !important;
  }
}

@media (max-width: 768px) {
  .modal-inside,
  .modal-inside-wrapper {
    width: 100% !important;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .modal-inside,
  .modal-inside-wrapper {
    width: 100% !important;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .modal-inside,
  .modal-inside-wrapper {
    width: 100% !important;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
