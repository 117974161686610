@import "./variables";
@import "./mixins";

.dashboard-layout {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}

.table-responsive {
  overflow: auto;
}

.main-content {
  position: relative;
  min-height: 100vh;
  //width: calc(100% - 280px);
  color: white;
  padding: 20px 30px;

  .content {
    width: 100%;
    margin: 0px;
    .col-lg-3,
    .col-lg-6 {
      padding-top: 6px;
      padding-left: 3px !important;
      padding-right: 3px !important;
    }
  }
  @include mdMaxBreakPoint {
    width: calc(100% - 55px);
  }
}

.dashboard-card {
  background: #131419;
  border-radius: 5px;
  padding: 15px;
  height: 100%;
  width: 100%;
  min-height: 180px;
  margin-right: 20px;
  @media (min-width: 1500px) {
    padding: 25px;
  }
  @media (min-width: 1700px) {
    padding: 35px;
  }
}

.main-card {
  background: #131419;
  border-radius: 5px;
  padding: 15px;
  height: 100%;
  width: 100%;
  min-height: 180px;
  margin-right: 20px;
  @media (min-width: 1500px) {
    //padding: 25px !important;
  }
  @media (min-width: 1700px) {
    //padding: 35px !important;
  }
}

.group-input {
  label {
    color: #ffffff;
    font-weight: 600;
    font-size: 12px;
  }
  input {
    padding: 10px 22px;
  }
  input,
  select,
  .custom-select {
    margin-top: 7px;
    background: #07080d;
    border: 0px;
    border-radius: 5px;
    width: 100%;
    height: 44px;
    color: #797777;
    font-weight: 600;
    font-size: 12px;
    margin: 0;
    outline: none;
    .select-selected {
      padding: 12px 22px;
      width: 100%;
      height: 100%;

      img {
        height: 25px;
        margin-right: 12px;
      }
    }

    .select-items {
      div {
        padding: 12px 22px;
        width: 100%;
        height: 100%;

        img {
          height: 25px;
          margin-right: 12px;
        }
      }
    }
  }
  select,
  .custom-select {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;

    background-image: url("../assets/icons/arrow-down.svg");
    background-position: 95% center;
    background-repeat: no-repeat;
  }
}

.subnet-input {
  label {
    color: #ffffff;
    font-weight: 600;
    font-size: 12px;
  }
  input {
    padding: 10px 22px;
  }
  input,
  select,
  .custom-select {
    margin-top: 7px;
    background: #07080d;
    border: 0px;
    border-radius: 5px;
    width: 100%;
    height: 44px;
    color: #797777;
    font-weight: 600;
    font-size: 12px;
    margin: 0;
    outline: none;
    .select-selected {
      padding: 12px 22px;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
    }
  }
  select,
  .custom-select {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;

    background-image: url("../assets/icons/arrow-down.svg");
    background-position: 95% center;
    background-repeat: no-repeat;
  }
}

::-webkit-scrollbar {
  width: 3px;
  height: 100%;
}

::-webkit-scrollbar-track {
  background: #07080d !important;
}

::-webkit-scrollbar-thumb {
  background: #4f5052;
}

.check-container {
  display: block;
  position: relative;
  cursor: pointer;
  margin-bottom: 16px;
  font-size: 22px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.check-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.check-container .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background: #92f8ff;
  box-sizing: border-box;
  border-radius: 3px;
}
.check-container .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.check-container input:checked ~ .checkmark:after {
  display: block;
}
.check-container .checkmark:after {
  left: 6px;
  top: 1px;
  width: 5px;
  height: 12px;
  border: solid #000;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.check-container.gray .checkmark {
  border: 2px solid rgba(98, 98, 98, 0.3);
}
.check-container.gray .checkmark:after {
  border-width: 0 2px 2px 0;
}

.slider-wrap label {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;
  margin-bottom: 6px;
}
.rc-slider {
  .rc-slider-rail,
  .rc-slider-track,
  .rc-slider-step {
    height: 7px;
  }

  .rc-slider-rail {
    background: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(57.0529px);
  }

  .rc-slider-dot {
    display: none;
  }

  .rc-slider-handle {
    background: linear-gradient(122.66deg, #92f8ff 19.88%, #92f8ff 77.65%);
    border: 1.5px solid #07080d;
    box-shadow: 0px 0px 28.2385px rgba(0, 0, 0, 0.4);
    border-radius: 5px;
    opacity: 1;
    width: 29px;
    height: 19px;
  }

  .rc-slider-handle-dragging {
    box-shadow: none !important;
    border: none !important;
  }

  .rc-slider-mark {
    left: 8px;
    width: 93%;

    .rc-slider-mark-text {
      color: rgba(255, 255, 255, 0.4);
      font-weight: 500;
      font-size: 13px;
      line-height: 17px;
      margin-top: 14px;
      width: 100%;
      @media screen and (max-width: 635px) {
        font-size: 10px !important;
      }
    }

    .rc-slider-mark-text-active {
      font-weight: 700;
      color: white;
      margin-top: 10px;
    }
  }
}
