.auth-page {
  min-width: 100vw;
  min-height: 100vh;
  background: url("../../assets/auth-l-bg.svg") left top no-repeat,
    url("../../assets/auth-r-bg.svg") right bottom no-repeat;
  background-size: 40%, 25%;
  display: flex;
  .logo {
    margin-top: 50px;
  }
  .auth-link {
    font-weight: 600;
    font-size: 12px;
    color: #92f8ff;
    text-decoration: none;
    margin-left: 5px;
    &:hover {
      text-decoration: underline;
    }
  }
  button {
    background: #07080d;
    border-radius: 5px;
    transition: 0.3s;
    border: 0px;
    outline: 0px;
    height: 44px;
    font-weight: 600;
    font-size: 14px;
    &#login-button,
    &#register-button {
      color: #92f8ff;
      width: 60%;
      &:hover {
        color: white;
      }
    }
  }
  .content {
    margin: 5% auto 50px auto;
    width: 90%;
    max-width: 520px;
    display: flex;
    flex-direction: column;
    @media (max-width: 760px) {
      margin-top: 10%;
    }
    & > .title {
      font-weight: 800;
      font-size: 22px;
      text-align: center;
      margin-bottom: 20px;
      & > .white {
        color: #ffffff;
      }
      & > .blue {
        color: #ffffff;
        color: #92f8ff;
      }
    }
    .auth-card {
      width: 100%;
      background: #131419;
      padding: 43px 45px 30px 45px;
      height: 474px;
      .check-group {
        margin-bottom: 30px;
        display: flex;
        margin-left: 5px;
        .label {
          color: #5f5f5f;
          font-weight: 600;
          font-size: 12px;
          margin-left: 25px;
        }
      }
      input {
        width: 100%;
        display: block;
        height: 44px;
        padding-left: 22px;
        border: 0;
        outline: 0;
        margin-bottom: 30px;
        background: #07080d;
        border-radius: 5px;
        &,
        &::placeholder {
          color: #6f6f6f;
          font-weight: 600;
          font-size: 14px;
        }
        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
          -webkit-text-fill-color: #6f6f6f;
        }
      }
    }
  }

  .link-group {
    text-align: center;
    span {
      font-weight: 600;
      font-size: 12px;
      color: #5f5f5f;
    }
  }
}

.login-page {
  .link-group {
    text-align: center;
    span {
      font-weight: 600;
      font-size: 12px;
      color: #5f5f5f;
    }
  }

  .login-with {
    flex-direction: column;
    margin-top: 30px;
    margin-bottom: 30px;
    span {
      font-weight: 600;
      font-size: 12px;
      color: #5f5f5f;
      margin-bottom: 15px;
    }
    button {
      color: white;
      width: 106px !important;
      img {
        margin-right: 10px;
      }
      &:first-child {
        margin-right: 5px;
      }
      &:last-child {
        margin-left: 5px;
      }
      &:hover {
        color: #92f8ff;
      }
    }
  }
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}
