.main-navbar{
    display: flex;
    justify-content: flex-end;
    width: 100%;
    .current-user{
        position: relative;
        transform: translateX(10px);
        height: 40px;
        .info{
            display: flex;
            justify-content: center;
            align-items: center;
            background: #13141A;
            border-radius: 5px;
            height: 33px;
            .name{
                margin: 0 10px;
                font-weight: 800;
                font-size: 14px;
                color: white;
            }
        }
    }
}